
@import  url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital@1&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}
body {
  background-color: black;
}
h1,h4 ,p,a{
  color: white;
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: white;
  color: black;
  font-weight: 600;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 5px;
}
.btn-light {
  background-color: transparent;
  color: white;
}
.btn:hover{
  background:black;
  color: white;
  transform: 0.6s;
  color: white;
}