.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}
.header-bg {
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 20px 0px 50px rgba(255, 255, 255, 0.5);
    transition: 0.6s;
}
.nav-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
     text-decoration: none;
}
.nav-menu li{
    padding: 0 1rem;
    list-style: none;
   
}
.nav-menu li a{
      font-size: 1.2rem;
      font-weight: 500;
      text-decoration: none;
}
.hamburger{
    display: none;
}
@media (max-width:1040px) {
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        position: absolute;
        top:0;
        left:-100%;
        z-index: -3;
        transition: 1s;
    }
    .nav-menu.active{
         left:0;
    }
    .nav-menu li {
        padding: 1rem 0;
    }
    .nav-menu li a{
        font-size: 2rem;
    }
    .hamburger{
        display: initial;
        cursor: pointer;
    }
}