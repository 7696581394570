.hero-img {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 60vh;
    position: relative;
}
.hero-img::before{
    content: "";
    background-image: url("https://wallpapers.com/images/featured/nucb4z97b3q78mjk.jpg");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
}
.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hero-img h1{
    font-size: 2.4rem;
}
.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}
@media screen and (max-width:640px) {
    .hero-img h1{
        font-size: 2rem;
    }
}